import React, { useState, useEffect } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import Logo1 from '../images/logo.jpg'; // Replace with actual logo 1
import Logo2 from '../images/logo2.webp'; // Replace with actual logo 2
import Image1 from '../images/slider1.webp'; // Replace with actual image 1
import Image2 from '../images/slider2.webp'; // Replace with actual image 2
import Image3 from '../images/slider3.webp'; // Replace with actual image 3
import Image4 from '../images/slider4.webp'; // Replace with actual image 4
import AboutImage from '../images/slider3.webp'; // Add an image for the About section

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [Image1, Image2, Image3, Image4];

  // Next slide function
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Previous slide function
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  // Automatically change slides every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3000);

    // Clear interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-gray-100">
      {/* Header with Logos at the Top */}
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl mx-auto px-6">
        {/* Left Logo */}
        <div className="flex-1 flex items-center mt-4 sm:mt-6 md:mt-8 rounded-full">
          <img src={Logo1} alt="Logo 1" className="h-20 sm:h-24 md:h-28 rounded-full ml-0 sm:ml-6" />
        </div>
        <div className="flex-1 text-center mt-4 sm:mt-6 md:mt-8 sm:mb-6 md:mb-8">
    <h1 className="text-2xl sm:text-3xl md:text-4xl font-cursive text-black font-bold animate-text-glow text-shadow-md">
      गायत्री गोशाळा दाभाडी
    </h1>
  </div>

  {/* Right Button with Animation */}
  <div className="flex-1 text-right mt-2 sm:mt-6 md:mt-8 sm:ml-4 md:ml-8 sm:pl-6 md:pl-8 lg:pr-16">
    <button className="relative bg-green-500 text-white w-28 h-28 rounded-full hover:bg-green-600 transform transition duration-500 ease-in-out flex items-center justify-center ml-auto shadow-lg animate-pulse">
      <span className="absolute inset-0 bg-gradient-to-r from-yellow-400 via-red-500 to-yellow-500 opacity-20 rounded-full animate-ping"></span>
      <span className="absolute inset-0 bg-gradient-to-r from-yellow-400 via-red-500 to-yellow-500 opacity-10 rounded-full animate-spin-slow"></span>
      <span className="relative z-10 flex items-center justify-center text-xs font-cursive shadow-md animate-glow font-bold">
        Online Gaushala Visit
      </span>
    </button>
  </div>
      </div>

      {/* Main Content */}
      <div className="bg-gray-100 py-6 mt-0">
        {/* Image Slider */}
        <div className="relative w-full mt-0 overflow-hidden">
          <div
            className="slider flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`, // Adjust the transformation to move full width of one image
            }}
          >
            {images.map((img, index) => (
              <div
                key={index}
                className="slide min-w-full h-96 relative"
                style={{ transition: 'transform 0.3s' }}
              >
                <img
                  src={img}
                  alt={`Image ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg absolute top-0 left-0"
                />
              </div>
            ))}
          </div>

          {/* Slider Controls */}
          <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-between">
            <button
              onClick={prevSlide}
              className="slider-prev bg-red-500 text-white p-4 rounded-full opacity-50 hover:opacity-100 transition-opacity duration-300 font-bold size-15"
            >
              {'<'}
            </button>
            <button
              onClick={nextSlide}
              className="slider-next bg-red-500 text-white p-4 rounded-full opacity-50 hover:opacity-100 transition-opacity duration-300 font-bold size-15"
            >
              {'>'}
            </button>
          </div>
        </div>

        {/* About Section */}
        <div className="max-w-screen-xl mx-auto px-6 py-12 bg-white shadow-lg rounded-lg mb-12">
          <div className="flex flex-col md:flex-row items-center">
            <div className="w-full md:w-1/2">
              <img src={AboutImage} alt="About Gayatri Gaushala" className="w-full h-72 object-cover rounded-lg shadow-md" />
            </div>
            <div className="w-full md:w-1/2 md:ml-8 mt-8 md:mt-0">
              <h2 className="text-3xl font-bold text-gray-800 mb-6 underline">About Gayatri Gaushala Dabhadi</h2>
              <p className="text-lg text-gray-700">
                Gayatri Goshala Dabhadi is dedicated to providing a safe and nurturing environment for cows. We focus on
                their health, well-being, and overall care while supporting the community with our sustainable initiatives.
                We protect, feed & shelter ailing, starving, destitute and stray Desi Cows & Bulls, the majority of which are abandoned by their owners or saved from butchers. Most of these Gauvansh are milk barren. These cows are brought to our Gaushala from tough conditions. All these cows are fed, sheltered, and looked after by hundreds of Gausewaks who work round the clock.
              </p>
            </div>
          </div>
        </div>

        {/* Vision & Mission Section */}
        <div className="max-w-screen-xl mx-auto px-6 py-12 bg-white shadow-lg rounded-lg mb-12">
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Vision */}
            <div className="w-full md:w-1/2 mb-6 md:mb-0">
              <h2 className="text-3xl font-bold text-gray-800 mb-4 underline">Our Vision</h2>
              <p className="text-lg text-gray-700">
                Our vision is to be a leading center of excellence for cow care and education, fostering a harmonious
                relationship between nature, cows, and society.
              </p>
            </div>

            {/* Mission */}
            <div className="w-full md:w-1/2">
              <h2 className="text-3xl font-bold text-gray-800 mb-4 underline">Our Mission</h2>
              <p className="text-lg text-gray-700">
                Our mission is to provide exceptional care for cows, promote awareness of their importance in Indian culture,
                and contribute to sustainable practices that benefit both the environment and the community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
