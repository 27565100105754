import React, { useState } from 'react';
import { FiMenu, FiMail, FiPhone, FiSearch, FiShoppingCart, FiUser, FiGlobe, FiChevronDown, FiHome, FiDollarSign, FiHeart, FiGithub, FiCalendar, FiGift, FiLink, FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom'; // Import Link for routing
import Logo from '../images/logo.jpg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState(false);
  const [isDonationBookOpen, setIsDonationBookOpen] = useState(false);
  const [isAdoptAGauvanshOpen, setIsAdoptAGauvanshOpen] = useState(false);
  const [isMyDonationOpen, setIsMyDonationOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="bg-red-500 p-2 text-white">
      <div className="w-full flex items-center justify-between">
        {/* Menu button */}
        <button
          onClick={toggleMenu}
          className="flex items-center bg-yellow-200 p-3 rounded-full text-xl focus:outline-none absolute left-4 py-2 text-black"
        >
          <FiMenu className="text-black" />
          <span className="ml-2 hidden lg:inline text-black">Menu</span>
        </button>

        {/* Right side content container */}
        <div className="flex items-center space-x-6 lg:space-x-6 ml-auto pr-4">
          {/* Contact Info */}
          <div className="hidden lg:flex flex-col items-start space-y-1 mr-2">
            <span className="flex items-center space-x-1 text-red-500 font-bold hover:text-red-600">
              <FiPhone className="text-white" />
              <span className="text-white">+919765223337</span>
            </span>
            <span className="flex items-center space-x-1 text-red-500 font-bold hover:text-red-600">
              <FiMail className="text-white" />
              <span className="text-white">sominathjaiwal@gmail.com</span>
            </span>
          </div>

          {/* Search Box */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="hidden sm:block pl-10 pr-4 py-2 rounded-full text-gray-700 focus:outline-none focus:ring focus:border-blue-300"
            />
            <FiSearch className="block sm:hidden text-xl text-white cursor-pointer" />
            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden sm:block" />
          </div>

          {/* Language Selector */}
          <div className="relative hidden sm:flex items-center space-x-2 border rounded-full px-2 py-2 focus:outline-none focus:ring">
            <FiGlobe className="text-white" />
            <select className="bg-transparent text-white focus:outline-none hover:text-black">
              <option>Select Language</option>
              <option>Marathi</option>
              <option>Hindi</option>
              <option>English</option>
            </select>
          </div>

          {/* Cart Button */}
          <button className="bg-green-500 text-white px-3 py-2 rounded-full hover:bg-green-600 flex items-center space-x-1 focus:outline-none">
            <FiShoppingCart className="text-black" />
          </button>

          {/* Donate and Login Buttons */}
          <button className="bg-yellow-400 text-black px-3 py-2 rounded-full hover:bg-yellow-500 focus:outline-none">
            Donate Now
          </button>
          <button className="bg-white text-blue-500 px-3 py-2 rounded-full hover:bg-gray-200 flex items-center space-x-1 focus:outline-none">
            <FiUser />
            <span className="font-bold">Login</span>
          </button>
        </div>
      </div>

      {/* Side Drawer Menu */}
      <div className={`fixed top-0 left-0 w-64 h-full bg-white text-black transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-40`}>
        <div className="flex justify-between items-center p-4 bg-red-600">
          <div className="flex items-center space-x-5">
            <img src={Logo} alt="Logo" className="h-8" />
            <p className="font-bold text-white">Gayatri Gaushala</p>
          </div>
          <button onClick={toggleMenu} className="text-2xl text-white focus:outline-none">
            <IoClose />
          </button>
        </div>

        <nav className="flex flex-col p-4 space-y-4 ">
          <Link to="/" className="flex items-center space-x-2 text-black font-bold hover:text-red-600">
            <FiHome className="text-red-500" />
            <span>Home</span>
          </Link>

          

          <Link to="/contact" className="flex items-center space-x-2 text-red-500 font-bold hover:text-red-600">
            <FiMail className="text-red-500" />
            <span className="text-black">Contact Us</span>
          </Link>

          {/* Social Media Icons */}
         
        </nav>
        <div className="flex items-center space-x-4 mt-20 ml-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-400">
              <FiFacebook className="text-2xl" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-400">
              <FiTwitter className="text-2xl" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-red-800 hover:text-blue-400">
              <FiInstagram className="text-2xl" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-400">
              <FiLinkedin className="text-2xl" />
            </a>
          </div>
      </div>
    </header>
  );
};

export default Header;
