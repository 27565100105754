import React from 'react';

const Contact = () => {
  return (
    <div className="contact-section py-12">
      <div className="max-w-screen-xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Contact Us</h2>
        <p className="text-lg text-gray-700 mb-4">Feel free to reach out to us for any queries or concerns!</p>
        <form className="bg-white p-6 rounded-lg shadow-md">
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              className="w-full p-3 mt-2 border border-gray-300 rounded-lg"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              className="w-full p-3 mt-2 border border-gray-300 rounded-lg"
              placeholder="Your Email"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-gray-700">Message</label>
            <textarea
              id="message"
              className="w-full p-3 mt-2 border border-gray-300 rounded-lg"
              placeholder="Your Message"
              rows="5"
            ></textarea>
          </div>
          <button type="submit" className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600">
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
