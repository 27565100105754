import React from 'react';
import Logo1 from '../images/logo.jpg';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="max-w-screen-xl mx-auto px-6 space-y-12">
        
        {/* Top Section */}
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start space-y-8 md:space-y-0">
          
          {/* Logo and Description */}
          <div className="flex flex-col items-center md:items-start text-center md:text-left space-y-4">
            <img src={Logo1} alt="Gayatri Gaushala Logo" className="h-20 w-auto" />
            <p className="text-sm text-gray-400 max-w-xs">
              Gayatri Gaushala Dabhadi is dedicated to providing a safe and nurturing environment for cows. We focus on their health, well-being, and overall care while supporting the community with our sustainable initiatives.
            </p>
          </div>

{/* Social Media Links */}
<div className="flex flex-col items-center md:items-start">
            <h3 className="text-lg font-semibold mb-4">Social Links</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-yellow-400 transition">
                <i className="fab fa-facebook-f text-2xl"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-yellow-400 transition">
                <i className="fab fa-twitter text-2xl"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-yellow-400 transition">
                <i className="fab fa-instagram text-2xl"></i>
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-yellow-400 transition">
                <i className="fab fa-youtube text-2xl"></i>
              </a>
            </div>
          </div>
          {/* Quick Links */}
          <div className="text-center md:text-left">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/about" className="hover:text-yellow-400 transition">About Us</a></li>
              <li><a href="/donate" className="hover:text-yellow-400 transition">Donate</a></li>
              <li><a href="/contact" className="hover:text-yellow-400 transition">Contact</a></li>
              <li><a href="/volunteer" className="hover:text-yellow-400 transition">Volunteer</a></li>
            </ul>
          </div>

          
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-700 pt-6 text-center">
          <p className="text-sm text-gray-400">
            &copy; {new Date().getFullYear()} Gayatrigaushala.in. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
